.footer{
    background: #fa623e;
    font-family: "Poppins", sans-serif;
    a{
        text-decoration: none;
    }


    .footer-container{
        padding: 5%;
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #fff;
            padding: 0 0 5% ;

            .text{
                font-weight: 500;
                color: #fff;
                font-size: 20px;
            }
            .input-submit{
                display: flex;
                gap: 15px;

                .input{
                    display: flex;
                    gap: 7px;
                    padding: 5px 15px;
                    border: 1px solid #fff;
                    border-radius: 8px;

                    input{
                        background: transparent;
                        outline: none;
                        border: none;
                        resize: none;
                    }
                    input::placeholder{
                        color: #1C1F2375;

                    }
                }
                .submit{
                    background: #fff;
                    color: #fa623e;
                    border: none;
                    outline: none;
                    resize: none;
                    border-radius: 8px;
                    padding: 5px 25px;
                    font-weight: 400;
                }
            }
        }

        .container-2{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #fff;
            padding: 3% 0;

            .first{
                display: flex;
                flex-direction: column;
                gap: 13px;

                .brand-txt{
                    font-size: 14px;
                    font-weight: 400;
                    color: #1C1F23;
                }
                .footer-icons{
                    display: flex;
                    gap: 2px;
                    // align-self: center;

                    .footer-icon{
                        font-size: 23px;
                        width: 25px;
                        color: #fff;
                    }
                }
            }

            .sec{
                display: flex;
                gap: 20px;

                .footer-links{
                    
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .footer-link{
                        color: #fff;
                        font-weight: 400;
                        font-size: 13px;
                   }
                    
                } 
            }

        }
        .container-3{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2% 0;

            .first{
                .trademark{
                    color: #fff;
                    font-weight: 500;
                    font-size: 13px;
                    width: 100%;
                    display: flex;
                    align-items: center;

                }
            }
            .sec{
                display: flex;
                gap: 30px;

                .l-foot-link{
                    color: #fff;
                    font-size: 13px;

                }
            }

        }
    }

    .footer-img{
        width: 100%;
        height: 170px;
    }
}

@media (max-width: 900px) {
    .footer{
        // padding: 0;

        .footer-container{
            padding: 0;

            .container{
                flex-direction: column;
                padding: 3%;
                grid-area: 20px;

                .text{
                    font-size: 17px;
                }
                .input-submit{
                    padding: 0 2.5%;
                    gap: 2%;
                    .input{
                        width: 70%;
                    }
                    .submit{
                        width: 25%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }
            }
            .container-2{
                flex-direction: column;
                gap: 30px;
                align-items: flex-start;
                justify-content: left;
                padding: 10% 3%;
                .first{

                    .f-logo{
                        width: 200px;
                        max-height: 100%;
                    }
                    .brand-txt{
                        font-size: 13px;
                    }
                }

                .sec{
                    justify-content: space-between;
                    width: 100%;
                }
            }
            .container-3{
                flex-direction: column-reverse;
            }

        }
        .footer-img{
            max-width: 100%;
            height: 170px;
        }
    
    }
}