.footer-1{
    background:  #1A1A19;
    font-family: "Poppins", sans-serif;
    a{
        text-decoration: none;
    }


    .footer-container-2{
        border-top: 1px solid #fff;

        .container-2{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #fff;
            padding: 3% ;

            .first{
                display: flex;
                flex-direction: column;
                gap: 13px;

                .brand-txt{
                    font-size: 14px;
                    font-weight: 400;
                    color: #fff;
                }
                .footer-icons{
                    display: flex;
                    gap: 2px;
                    // align-self: center;

                    .footer-icon{
                        font-size: 23px;
                        width: 25px;
                        color: #fff;
                    }
                }
            }

            .sec{
                display: flex;
                gap: 20px;

                .footer-links{
                    
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .footer-link{
                        color: #fff;
                        font-weight: 400;
                        font-size: 13px;
                   }
                    
                } 
            }

        }
        .container-3{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3% ;

            .first{
                .trademark{
                    color: #fff;
                    font-weight: 500;
                    font-size: 13px;
                    width: 100%;
                    display: flex;
                    align-items: center;

                }
            }
            .sec{
                display: flex;
                gap: 30px;

                .l-foot-link{
                    color: #fff;
                    font-size: 13px;

                }
            }

        }
    }

    .footer-img{
        width: 100%;
        height: 170px;
    }
}

@media (max-width: 900px) {
    .footer-1{

        .footer-container-2{
            .container-2{
                flex-direction: column;
                gap: 30px;
                align-items: flex-start;
                justify-content: left;
                padding: 10% 3%;
                .first{

                    .f-logo{
                        width: 200px;
                        max-height: 100%;
                    }
                    .brand-txt{
                        font-size: 13px;
                    }
                }

                .sec{
                    justify-content: space-between;
                    width: 100%;
                }
            }
            .container-3{
                flex-direction: column-reverse;
            }
        }
        .footer-img{
            max-width: 100%;
            height: 170px;
        }
    
    }
}