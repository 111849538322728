@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=inter");

@font-face {
  font-family: "Dymbrud";
  src: url("../../assets/font/Dymbrud.ttf") format("truetype"),
    url("../../assets/font/Dymbrud.otf") format("woff");
  font-weight: normal;
  font-style: normal;
}

.carousel-button-group{
  display: flex;
  gap: 10px;
  position: absolute;
  top: -43%;
  right: 5%;

  .prev-and-next {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    outline: none;
    border: none;
    resize: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon {
      color: #fa623e;
      font-size: 20px;
    }
  }

}
.home {
  // background: #1a1a19;
  padding: 2% 0%;
  font-family: "Poppins", sans-serif;

  a {
    text-decoration: none;
  }

  .banner {
    padding: 0 0 0 15%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .top {
      display: flex;
      flex-direction: column;
      h1 {
        font-weight: 700;
        color: #fff;
        font-size: 2.5rem;

        .dymbruid {
          font-family: "Dymbrud", sans-serif;
          font-weight: 400;
        }
      }
      .top-text {
        color: #fff;
        font-weight: 400;
      }
    }
    .button-div {
      display: flex;
      gap: 15px;

      .download-btn {
        border: none;
        background: #fa623e;
        font-weight: 600;
        padding: 5% 3%;
        width: 250px;
        display: flex;
        gap: 10px;
        align-items: center;
        color: #fff;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
  .banner-img {
    max-width: 100%;
    // max-height: 200px;
  }
  .features {
    // margin-top: 30px;

    .top {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      padding: 3% 17%;
      align-items: center;
      text-align: center;

      h3 {
        font-weight: 400;
        font-size: 2rem;
        font-family: "Dymbrud", sans-serif;
        color: #fff;
      }
      .features-cards {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .card {
          border: 1px solid #f6f6f6;
          display: flex;
          flex-direction: column;
          padding: 20px;
          border-radius: 26px;
          width: 33.3%;
          // width: 317px;

          .card-head {
            font-weight: 500;
            color: #fa623e;
            font-family: "Poppins", sans-serif;
          }
          .p {
            color: #fff;
            font-family: "Poppins", sans-serif;
          }
        }
      }

      .fix-problems-btn {
        margin-top: 30px;
        font-family: "Poppins", sans-serif;
        font-size: 33px;
        font-weight: 500;
        text-align: left;
        color: #fff;
      }
    }
  }
  .empowering-phone {
    display: flex;
    padding: 3% 5%;
    align-items: center;

    .empowering {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 50%;

      .emp-text {
        width: 75%;
        color: #fff;
        font-size: 1.5rem;
        font-weight: 500;
      }

      .circles {
        display: flex;
        gap: 15px;
        align-items: center;
        padding: 0 0 0 3%;

        .circle {
          // border: 1px solid #fa623e;
          height: 30px;
          width: 30px;
          padding: 7px;
          border-radius: 100%;
          background: #fa623e;
        }
        .store-icons {
          height: 30px;
          display: block;
          margin: 0 auto;
        }
      }
    }
    .phone-div {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 600px;
      position: relative;

      .obj {
        position: absolute;
        right: -12%;
        top: 5%;
      }

      .phone-bg {
        background: #ffb19f;
        height: 400px;
        padding: 24px;
        border-radius: 32px;
        position: absolute;

        .phone {
          position: relative;
          top: -110px;
          width: 250px;
          height: 500px;
        }
      }
    }
  }
  .categories {
    .react-multiple-carousel__arrow {
      display: none;
    }
    height: 170px;
    background: #fa623e;

    .cat-top {
      padding: 1% 7%;
      display: flex;
      justify-content: space-between;

      .select-text {
        color: #1c1f23;
        font-family: "Inter", sans-serif;
        font-weight: 600;
      }
      .cat-btn {
        display: flex;
        gap: 10px;

        display: none;
        .prev-and-next {
          width: 30px;
          height: 30px;
          border-radius: 100%;
          outline: none;
          border: none;
          resize: none;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .icon {
            color: #fa623e;
            font-size: 20px;
          }
        }
      }
    }
    .cat-bottom {
      position: relative;
      padding: 1% 2%;
      // display: flex;
      // align-items: center;

      .artisan-box {
        background: #ffd8cf;
        border-radius: 9px;
        // height: 50px;
        padding: 10px 25px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 10px;
        .art-img {
          width: 40px;
        }
        .artisan-text {
          color: #212121;
          font-family: "Inter", sans-serif;
          font-weight: 400;
          font-size: 22px;
        }
      }
    }
  }
  .become-a-fixer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 15%;

    .first {
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;

      .obj-isolation {
        position: absolute;
        left: -100%;
        bottom: -30%;
        width: 100px;
      }

      .fixer-text {
        font-weight: 500;
        color: #fff;
        font-size: 55px;
        font-family: "Poppins", sans-serif;
      }
      .sign-up {
        background: #fa623e;
        resize: none;
        outline: none;
        border: none;
        padding: 2% 5%;
        font-size: 23px;
        border-radius: 8px;
        font-family: "Poppins", sans-serif;
        color: #fff;
      }
    }

    .artisan {
      width: 280px;
    }
  }
  .features-with-image {
    display: flex;
    padding: 5% 15%;
    justify-content: space-between;

    .features-container {
      width: 269px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      justify-content: center;

      .white-circle {
        width: 70px;
        height: 70px;
        background: #fff;
        border-radius: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .circle-img {
          width: 60px;
        }
      }
      .text {
        color: #fff;
        margin: 0 auto;
        font-family: "Poppins", sans-serif;
        font-size: 17px;
      }
    }
  }
  .last-div-container {
    position: relative;
    background: #1a1a19;
    padding: 3% 0;

    .left-isolation {
      position: absolute;
      left: 5%;
      width: 100px;
    }
    .right-isolation {
      position: absolute;
      width: 100px;
      right: 3%;
    }
    .bottom-left-isolation {
      position: absolute;
      width: 100px;
      left: -1%;
    }
    .bottom-right-isolation {
      position: absolute;
      width: 100px;
      right: 0;
    }
    .luanch-div {
      padding: 10% 15%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .center-isolation {
        position: absolute;
        width: 450px;
        left: 25%;
        top: 11%;
      }

      .luanch-txt {
        color: #fff;
        font-weight: 600;
        font-size: 55px;
      }
      .join-btn {
        background: #fa623e;
        border-radius: 8px;
        border: none;
        outline: none;
        color: #fff;
        font-weight: 400;
        font-size: 17px;
        padding: 10px;
        line-height: 1;
      }
    }
    .box-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0 10%;
      position: relative;

      .obj-isolation {
        position: absolute;
        left: 0;
        width: 100px;
      }
      .box {
        background: #ffb19f;
        width: 40%;
        padding: 25px;
        border-radius: 12px;
      }
    }
  }
}

@media (max-width: 900px) {
  .carousel-button-group{
    top: -65%;
  }
  .home {
    width: 100% !important;
    .banner {
      padding: 3%;
      align-items: center;
      justify-content: center;

      .top {
        gap: -15px;

        h1 {
          font-size: 1.7rem;
        }
        .top-text {
          font-size: 1rem;
        }
      }
      .button-div {
        padding: 0 5%;
        width: 100%;
        flex-direction: column;

        .download-btn {
          width: 100% !important;
          padding: 3% 0;
          justify-content: center;
        }
      }
    }
    .banner-img {
      margin-top: 50px;
    }
    .features {
      // width: 100%;
      .top {
        padding: 10%;
      }
      .features-cards {
        flex-direction: column;
        align-items: center;
        gap: 50px !important;
        padding: 5%;

        .card {
          width: 100% !important;
        }
      }
      .fix-problems-btn {
        display: none;
      }
    }
    .empowering-phone {
      flex-direction: column-reverse;
      padding: 10% 2%;

      .empowering {
        width: 100% !important;
        text-align: center;
        padding: 5%;
        gap: 40px;

        .emp-text {
          width: 100%;
          font-size: 17px;
        }
        .circles{
          justify-content: center;
        }
      }
      .phone-div {
        width: 100% !important;
        .obj {
          right: -1%;
          top: -10%;
        }
      }
    }
    .categories{
      height: 110px;
      padding: 15px 5px 35px;

      .cat-bottom{
        margin-top: 20px;
      }
    }
    .become-a-fixer {
      padding: 25% 5%;
      .first {
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;

        .fixer-text {
          font-size: 27px;
        }
        .sign-up {
          width: 170px;
        }
      }

      .artisan {
        width: 100px;
        display: none;
      }
    }
    .features-with-image {
      flex-direction: column;
      gap: 25px;

      .features-container {
        width: 100%;
        text-align: center;
      }
    }
    .last-div-container {
      padding: 10% 0 20%;
      .luanch-div {
        padding: 30% 5%;
        .center-isolation {
          display: none;
        }
        .luanch-txt {
          font-size: 23px;
        }
      }
      .box-container{
        .box{
          width: 60% !important;
        }
        .obj-isolation{
          width: 15%;
        }
      }
    }
  }
}
