@import url("https://fonts.googleapis.com/css2?family=Poppins");
.about{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 2% 5% 30%;
    font-family: "Poppins", sans-serif;

    .span{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .head{
            font-weight: 600;
            font-size: 2rem;
            color:#FFB19F;
            
        }
        .txt{
            color: #fff;
        }
    }
}