@import url("https://fonts.googleapis.com/css2?family=Poppins");
.contact {
  text-align: center;
  padding: 2% 15% 4%;
  font-family: "Poppins", sans-serif;

  .head {
    font-weight: 600;
    color: #fff;
    font-size: 2rem;
  }
  .flex {
    display: flex;
    flex-direction: row;
    // height: 100%;
    justify-content: center;
    align-items: center;
    text-align: left;

    .first {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .imgs-container {
        position: relative;
        width: 400px;
        height: 100%;

        .r-rect {
          max-width: 100%;
          height: 450px;
          position: absolute;
          top: 46%;
          left: 46%;
          transform: translate(-50%, -46%);
        }
        .b-rect {
          max-width: 100%;
          height: 450px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .person {
          max-width: 100%;
          height: 450px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .t-l-cloud{
          position: absolute;
          right: 5%;
          // width: 130px;
          // bottom: 95%;
        }
      }
    }

    .sec {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .input-div {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .label {
          color: #ffb19f;
          font-weight: 400;
        }

        input {
          background: transparent;
          border: 1px solid #ededed;
          height: 45px;
          border-radius: 8px;
          color: #ededed;
        }
      }
      .btn {
        background: #fa623e;
        border: none;
        outline: none;
        resize: none;
        border-radius: 8px;
        width: 105px;
        height: 38px;
        color: #ededed;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 900px) {
  .contact{
    padding: 2% 5%;
    .flex{
      flex-direction: column-reverse;
      gap: 250px;

      .first{
        width: 100% !important;
      }
      .sec{
        width: 100% !important;
      }
    }
  }
}
