.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3% 5% 1%;

  .nav-item-container {
    display: flex;
    gap: 100px;

    .nav-links {
      display: flex;
      gap: 20px;

      a {
        text-decoration: none;

        &.active-nav-item .nav-item {
          color: #fa623e; // Change this color to whatever you want for the active link
          font-weight: bold;
        }
      }

      .nav-item {
        font-size: 17px;
        color: white;
        font-family: 'Poppins', sans-serif;

        &:hover {
          color: #fa623e;
        }
      }
    }

    .nav-btn-div {
      .nav-btn {
        background: #fa623e;
        border-radius: 5px;
        font-size: 17px;
        color: #fff;
        padding: 5% 0;
        width: 100px;
        border: none;
        outline: none;
        font-family: 'Poppins', sans-serif;
      }
    }
  }
  .d-lg-none{
    display: none;
  }
}

@media (max-width: 900px) {
  .navbar{
    padding: 3%;

    .nav-item-container{
      display: none;
    }
    .d-lg-none{
      display: block;
      background: transparent;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 8px;
      padding: 3px 10px;
    }
    .nav-item-container-side{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100vh;
      padding: 10px;
      gap: 8px;
      position: absolute;
      top: 67px;
      z-index: 1;
      right: 0;
      background: #1a1a19;
      width: 215px;

      .nav-links {
        display: flex;
        gap: 10px;
        flex-direction: column;

  
        a {
          text-decoration: none;
  
          &.active-nav-item .nav-item {
            color: #fa623e; // Change this color to whatever you want for the active link
            font-weight: bold;
          }
        }
  
        .nav-item {
          font-size: 17px;
          color: white;
          font-family: 'Poppins', sans-serif;
  
          &:hover {
            color: #fa623e;
          }
        }
      }
      .nav-btn-div {
        .nav-btn {
          background: #fa623e;
          border-radius: 5px;
          font-size: 17px;
          color: #fff;
          padding: 5% 0;
          width: 100px;
          border: none;
          outline: none;
          font-family: 'Poppins', sans-serif;
        }
      }
  
  

    }
  }
}